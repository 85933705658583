import React from "react"

import Layout from "components/Layout"
import Seo from "components/Seo"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"
import BanerContact from "components/BanerContact"

import { Content } from "page_components/payments"

const Payments = ({ location }) => (
  <Layout location={location} isGrayMain>
    <Breadcrumbs title="Payment methods" />
    <PageHeader title="Payment methods" />
    <Content />
    <BanerContact />
  </Layout>
)

export const Head = () => <Seo title="Payment methods" />

export default Payments
